<template>
  <section >
    <h2 class="titulo-links-escolha">CADASTRAR DISCIPLINA</h2>

    <pm-Card>
      <template v-slot:content>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12 lg:col-3">
            <label>Nome da Disciplina</label>
            <pm-InputText  v-model="disciplinaEscolar.nome"  />
          </div>
          <div class="field col-12 md:col-12 lg:col-3">
            <label>Carga Horária</label>
            <pm-InputText :style="errorColor" type="number" @input="verificarCh" @change="verificarCh" v-model="disciplinaEscolar.carga_horaria" />
            <small v-if="notInt" v-show="notInt" class="p-error">DIGITE APENAS NÚMEROS INTEIROS. </small>
          </div>

        </div>
        <div class="grid grid-nogutter justify-content-between">
          <pm-Button label="Voltar"  class="p-button-danger" icon="pi pi-angle-left" iconPos="left" @click="voltar()" />

          <pm-Button   class="p-button-success"
             label="Cadastrar" @click="cadastrar" v-if="disciplinaEscolar.nome
                                                        && disciplinaEscolar.nome.length > 3
                                                        && verificarCh() == true"

             icon="pi pi-check" iconPos="right"
           />
        </div>
      </template>
    </pm-Card>

  </section >
</template>

<script>

import { DisciplinaEscolar } from "@/class/disciplina.js";
import store_token_info from "@/store/store_token_info.js";


export default {
  components: {

  },
  name: 'Disciplina-Escolar',
  data () {
    return {
      disciplinaEscolar: {
        nome: null,
        user_id: store_token_info.usuario_logado,
        carga_horaria: null,
      },
    }
  },
  methods: {
    voltar(){
      this.$router.push({name: 'coordenacao-disciplina'});
    },

    //verifica se a carga horária é um int
    verificarCh(){
      if(this.disciplinaEscolar.carga_horaria % 1 == 0){
        this.notInt = false
        this.errorColor = ""
        return true;
      }else{
        this.notInt = true;
        this.errorColor = "border-color: #f44336";
        return false;
      }
    },

    async cadastrar() {
      try {
        if (this.disciplinaEscolar.carga_horaria === null) {
          this.disciplinaEscolar.carga_horaria = 20;
        }
        const data = await DisciplinaEscolar.cadastrar(this.disciplinaEscolar);
        this.$vaToast.init({
          message: "Cadastrado com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }
      catch(e) {
        this.$vaToast.init({
          message: "Erro ao cadastrar!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          duration: 2500,
          fullWidth: false,
        })
      }
      this.$router.push({name: 'coordenacao-disciplina'});
    },
  },
}
</script>

<style>
  .row.row-inside {
    max-width: none;
  }

  .form-control:focus {
    background-color: #f6f7f6;
    border-color: #673ab7;
    box-shadow: none;
  }
  .form-control {
    background-color: #f6f7f6;
    border: 1px solid #f6f6f6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
     margin: 0;
  }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }


  .va-input-wrapper, fieldset {
    margin-bottom: 0.5rem;
  }
</style>
